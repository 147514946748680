@import "@styles/breakpoints.module.scss";

.header {
  height: 3.75rem;
  box-shadow: 0 4px 14px 0 rgba(55, 57, 70, 0.08);
  background-color: #ffffff;
  width: 100%;

  img {
    object-fit: contain;
    height: 1.8rem;
    margin-top: 15px;
    margin-left: 152px;
    margin-bottom: 15px;
  }
}

.container {
  text-align: center;
  flex: 1;
}

.image {
  width: 200px;
  height: 200px;
  margin-top: 48px;
}

.title {
  font-size: 29px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin: 0px;
}

.msg {
  font-size: 16px;
  color: #373946;
}

.button {
  width: 15rem;
  padding-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: $breakpoint-small) {
  .header {
    text-align: center;
    img {
      margin-left: inherit;
    }
  }
}
